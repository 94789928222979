<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            title="Nueva Venta"
            small
            fab
            @click="openWindow(null)"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel == 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="sucursales"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="setLocal()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" xl="3" class="py-1 pl-sm-0">
                  Local
                  <v-autocomplete
                    v-model="filtro.local"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="locales_user.filter(loc => loc.sucursal == filtro.sucursal)"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Nº Pedido
                  <v-text-field
                    v-model.trim="filtro.venta"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col :cols="big ? 12 : 6" :sm="big ? 8 : 3" :md="big ? 5 : 2" class="py-1">
                  Fecha
                  <FechaPickerRango
                    v-model="fechas"
                    :limpiar.sync="clear"
                    :rango_def="1"
                    @changeSize="changeSize"
                  />
                </v-col>
                <v-col v-if="!big && $vuetify.breakpoint.mdAndUp" cols="2"></v-col>
                <v-col v-if="$vuetify.breakpoint.xs" cols="2"></v-col>
                <v-col cols="5" sm="2" xl="1" class="py-1">
                  Vend. Cod.
                  <v-text-field
                    v-model.trim="filtro.vendedor_codigo"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="7" :sm="big ? 3 : 4" md="2" class="py-1">
                  Vendedor
                  <v-text-field
                    v-model.trim="filtro.vendedor_nombre"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Cliente Cod.
                  <v-text-field
                    v-model.trim="filtro.cliente_codigo"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                    @blur="getCliente()"
                  >
                    <template v-slot:append-outer>
                      <v-icon
                        class="mt-1"
                        color="info"
                        title="Buscar cliente"
                        small
                        @click="dialog_clientes.activo = true"
                      >
                        fas fa-search
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="7" sm="4" md="2" class="py-1">
                  Nombre
                  <v-text-field
                    v-model.trim="filtro.cliente_nombre"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="5" sm="3" md="2" class="py-1">
                  Documento
                  <v-text-field
                    v-model.trim="filtro.documento"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" :sm="big ? 12 : 5" md="2" xl="3" class="py-1 px-0">
                  <BtnFiltro
                    :loading="load"
                    class="pt-1"
                    @clear="limpiar()"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="id"
        :class="permiso_copiar_texto == 1? '' : 'prevent-select'"
        :headers="headers"
        :items="ventas"
        :loading="load"
        :search="search"
        :footer-props="{
          'items-per-page-options': [ 10, 15, 50, 100 ]
        }"
        mobile-breakpoint
        sort-desc
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col v-if="false" cols="6" sm="9" xl="10" class="d-flex justify-start align-center">
              <DownloadDataTable
                :data="ventas"
                :name="`Ventas ${moment(new Date()).format('YYYYMMDD HHmmss')}`"
                :headers="{
                  'Id': 'id',
                  'Fecha': 'fecha',
                  'Importe': 'importe',
                  'Vend. Nombre': 'vendedor_nombre',
                  'Cliente': 'cliente',
                  'Teléfono': 'telefono',
                  'Documento': 'documento',
                  'Nº Factura': 'factura'
                }"
              />
            </v-col>
            <v-col cols="6" sm="3" xl="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.importe`]="{ value }">
          {{ formatMoney(value) }}
        </template>
        <template v-slot:[`item.fecha`]="{ value }">
          {{ value ? moment(value).format('DD/MM/YYYY') : null }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="info"
            title="Ver"
            class="mr-2"
            small
            @click="openWindow(item.id)"
          >
            fas fa-eye
          </v-icon>
          <v-icon
            title="Factura"
            color="cyan"
            class="mr-2"
            small
            @click="botonFactura(item)"
          >
            fas fa-print
          </v-icon>
          <v-icon
            title="Remito"
            color="indigo"
            class="mr-2"
            small
            @click="getArchivo(item, 'Remito')"
          >
            fas fa-receipt
          </v-icon>
          <v-icon
            v-if="item.cobranza_id != null"
            title="Recibo"
            color="purple"
            small
            @click="getArchivo(item, 'Recibo')"
          >
            fas fa-receipt
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="info"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- COMPONENTES -->
    <BuscarClienteMayorista
      :datos="dialog_clientes"
      @setearCliente="setCliente"
      @setearBuscarClienteM="setDialogClienteMay"
    />
    <PDFViewer
      v-model="pdf_dialog"
      :pdf="pdf"
      :nombre="pdf_nombre"
      :titulo="pdf_nombre"
    />
  </v-row>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import { format_money, format_date } from '../../util/utils'
import { remitoReciboA4, facturaA4 } from '../../util/plantillas/pdfs'
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import DownloadDataTable from '../../components/util/DownloadDataTable.vue'
import FechaPickerRango from '../../components/util/FechaPickerRango.vue'
import BuscarClienteMayorista from '../../components/generales/clientes/BuscarClienteMayorista.vue'
import PDFViewer from '../../util/plantillas/PDFViewer.vue'

export default {
  data () {
    return {
      moment: moment,
      formatMoney: format_money,
      panel: 0,
      permiso_copiar_texto: 0,
      big: false,
      load: false,
      clear: false,
      pdf_dialog: false,
      pdf_nombre: '',
      pdf: null,
      vta_item: {},
      accion: '',
      search: '',
      headers: [
        { text: 'Id', value: 'id', cellClass: 'font-weight-bold' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Importe', value: 'importe', align: 'end' },
        { text: 'Vend. Cod.', value: 'vendedor_codigo', align: 'end' },
        { text: 'Vend. Nombre', value: 'vendedor_nombre', cellClass: 'font-weight-bold' },
        { text: 'Cliente', value: 'cliente' },
        { text: 'Documento', value: 'documento' },
        { text: 'Nº Factura', value: 'factura' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false }
      ],
      ventas: [],
      fechas: [ null, null ],
      filtro: {
        sucursal: null,
        local: null,
        vendedor_codigo: null,
        vendedor_nombre: null,
        cliente_codigo: null,
        cliente_nombre: null,
        documento: null,
        factura: null,
        venta: null,
        desde: null,
        hasta: null
      },
      dialog_clientes: {
        ruta: 'clientes/getClienteMayorista',
        activo: false,
        ejecutivo_codigo: 0
      }
    }
  },
  async created () {
    // obtengo los datos necesarios
    this.$store.state.loading = true
    await this.$store.dispatch('vendedores/get_locales_user')
    let res_permiso = await this.$store.dispatch('ventasMay/get_permisos_user')
    this.$store.state.loading = false

    // si tiene solo 1 sucursal se la selecciono
    if (this.una_sucursal) {
      this.filtro.sucursal = this.sucursales[0].id
      // si tiene favorito lo autoselecciono
      let favorito = this.locales_user.filter(lu => lu.favorito == 1)
      if (favorito.length == 1) {
        this.filtro.local = favorito[0].id
      }
    }
    if (res_permiso.exito == 1) {
      this.permiso_copiar_texto = res_permiso.permisos.copiar_seleccionar
    } else {
      this.$store.dispatch('show_snackbar', {
        text: res_permiso.message,
        color: 'error'
      })
    }
  },
  computed: {
    ...mapState('vendedores', ['locales_user']),
    ...mapState(['sucursales']),
    ...mapGetters(['una_sucursal'])
  },
  components: {
    BtnFiltro,
    SearchDataTable,
    DownloadDataTable,
    FechaPickerRango,
    BuscarClienteMayorista,
    PDFViewer
  },
  methods: {
    botonFactura (venta) {
      // si no tiene cae paso a facturar
      if (venta.cae == 0) {
        this.facturar(venta)
      } else {
        this.getFactura(venta.comprobante_caja_id)
      }
    },
    async facturar (venta) {
      this.$store.state.loading = true
      let respuesta = await this.$store.dispatch('afip/permiteFacturar', {
        comprobante_caja_id: venta.comprobante_caja_id
      })
      this.$store.state.loading = false

      if (respuesta.resultado != 1) {
        return this.$swal.fire({
          icon: 'error',
          title: respuesta.msj
        })
      }
      
      let modal = await this.$swal.fire({
        icon: 'info',
        html: `<h2>¿Está seguro de emitir un comprobante del tipo: <strong>${respuesta.comprobante_tipo}</strong> para el pedido Nº ${venta.id}?</h2>`,
        confirmButtonText: 'Facturar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      })
      if (!modal.isConfirmed) return

      this.$store.state.loading = true
      let fcPeticion = await this.$store.dispatch('afip/getFactura', {
        comprobante_caja_id: venta.comprobante_caja_id
      })
      this.$store.state.loading = false

      if (fcPeticion.resultado == 1) {
        await this.$swal.fire({
          icon: 'success',
          title: 'Se generó con Éxito la Factura',
          text: 'En breve se descargará el comprobante.',
          timer: 3000
        })
        this.getFactura(venta.comprobante_caja_id)
      } else {
        this.$swal.fire({
          icon: 'error',
          title: 'Error al generar la Factura',
          text: fcPeticion.msj
        })
      }
    },
    async getFactura (comp_caja_id) {
      this.$store.state.loading = true
      let respuesta = await this.$store.dispatch('caja/datos_factura', { comp_id: comp_caja_id })
      this.$store.state.loading = false

      if (respuesta.exito == 1) {
        const datos = respuesta.datos
        this.$store.state.loading = true
        this.pdf = await facturaA4(datos)
        this.$store.state.loading = false
        this.pdf_nombre = `${datos.encabezado.referencia}-${datos.encabezado.emynum}`
        this.pdf_dialog = true
      } else {
        this.$swal.fire({
          icon: 'error',
          title: respuesta.message
        })
      }
    },
    async getArchivo (venta, tipo) {
      this.$store.state.loading = true
      let result_re = await this.$store.dispatch('ventasMay/get_datos_pdf', {
        venta_id: venta.id,
        comprobante: tipo
      })
      this.$store.state.loading = false

      if (result_re.exito == 1) {
        this.$store.state.loading = true
        this.pdf = await remitoReciboA4(result_re.data, tipo)
        this.$store.state.loading = false
        this.pdf_nombre = `${tipo} N° ${result_re.data.numero}`
        this.pdf_dialog = true
      } else {
        this.$swal.fire({
          icon: 'error',
          title: result_re.message
        })
      }
    },
    async buscar () {
      this.ventas = []
      this.search = ''
      this.filtro.desde = format_date(this.fechas[0])
      this.filtro.hasta = format_date(this.fechas[1])

      this.load = true
      let result = await this.$store.dispatch('ventasMay/get_ventas', this.filtro)
      this.load = false

      if (result.exito == 1) {
        this.ventas = result.data
      } else {
        this.$swal.fire({
          icon: 'warning',
          title: result.message
        })
      }
    },
    async getCliente () {
      if (!this.filtro.cliente_codigo) return
      this.$store.state.loading = true
      let result = await this.$store.dispatch('clientes/getClienteMayorista', {
        codigo: this.filtro.cliente_codigo,
        ejecutivo_codigo: 0,
        nroDocumento: 0,
        nombre: ''
      })
      this.$store.state.loading = false

      if (result.resultado == 1) {
        if (result.clientes.length == 1) {
          this.filtro.cliente_nombre = result.clientes[0].vendedor_nombre
          this.filtro.documento = result.clientes[0].documento_numero
          return
        } else {
          this.$store.dispatch('show_snackbar', {
            text: 'No se encontró el cliente ' + this.filtro.cliente_codigo,
            color: 'info'
          })
        }
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.msj,
          color: 'error'
        })
      }
      this.filtro.cliente_codigo = null
      this.filtro.cliente_nombre = null
      this.filtro.documento = null
    },
    setDialogClienteMay (value) {
      this.dialog_clientes.activo = value
    },
    setCliente (item) {
      this.filtro.cliente_codigo = item.vendedor_codigo
      this.filtro.cliente_nombre = item.vendedor_nombre
      this.filtro.documento = item.documento_numero
    },
    setLocal () {
      let locales = this.locales_user.filter(loc => loc.sucursal == this.filtro.sucursal)
      this.filtro.local = locales.length == 1 ? locales[0].id : null
    },
    openWindow (id_venta) {
      const path = id_venta == null ? '/nueva-vta-mayorista' : `/vta-mayorista/${id_venta}`
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    changeSize (custom) {
      this.big = custom
    },
    limpiar () {
      this.clear = true
      this.fechas = [ null, null ]
      this.filtro = {
        sucursal: null,
        local: null,
        vendedor_codigo: null,
        vendedor_nombre: null,
        cliente_codigo: null,
        cliente_nombre: null,
        documento: null,
        factura: null,
        venta: null,
        desde: null,
        hasta: null
      }
    }
  }
}
</script>

<style scoped>
  .prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
</style>